body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(
      217deg,
      rgba(202, 218, 248, 1),
      rgba(255, 100, 100, 0) 70.71%
    ),
    linear-gradient(
      127deg,
      rgba(202, 240, 248, 1),
      rgba(100, 100, 255, 0) 70.71%
    ),
    linear-gradient(
      336deg,
      rgba(202, 248, 233, 1),
      rgba(100, 100, 255, 0) 70.71%
    );
	height: 100vh;
}
#root{
	height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
