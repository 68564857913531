h1 {
    color: #3fc0b8;
    text-shadow: 1px 1px 0 rgba(0, 0, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.4), 1px 1px 3px #969696;
    font-family: -apple-system, BlinkMacSystemFont, "Comic Sans MS",
   "Helvetica Neue", sans-serif;
  }
  
  .bg-main {
    background-color: rgba(63, 192, 184, 0.3);
  }
  
  .bg-subtle {
    background-color: rgba(245, 245, 245, 0.8);
  }
  
  .bg-subtle-transparent {
    background-color: rgba(245, 245, 245, 0.7);
  }
  
  .footer-text {
font-size: 9pt;  
}

  footer {
    background-color: rgba(245, 245, 245, 0.4);
    min-height: 1vh;
  }
  
  .board-row{
	  position: fixed;
	  bottom: 1.4rem;
  }
  
  .main-height {
    height: 86.5vh;
  }
  
  textarea,
  #textInput {
    height: 4.5rem;
    border: 2px solid rgba(245, 245, 245, 0.7);
    -webkit-box-shadow: 1px 2px 15px rgba(240, 240, 240, 0.7) inset, -2px -3px 15px rgba(240, 240, 240, 0.7) inset, 1px 2px 15px rgba(225, 225, 225, 0.7), -2px -3px 15px rgba(225, 225, 225, 0.7), 0 0 5px rgba(205, 205, 205, 0.7);
            box-shadow: 1px 2px 15px rgba(240, 240, 240, 0.7) inset, -2px -3px 15px rgba(240, 240, 240, 0.7) inset, 1px 2px 15px rgba(225, 225, 225, 0.7), -2px -3px 15px rgba(225, 225, 225, 0.7), 0 0 5px rgba(205, 205, 205, 0.7);
    -webkit-transition: border 1s, -webkit-box-shadow 1s;
    transition: border 1s, -webkit-box-shadow 1s;
    transition: box-shadow 1s, border 1s;
    transition: box-shadow 1s, border 1s, -webkit-box-shadow 1s;
  }
  
  textarea:focus,
  #textInput:focus {
    border: 2px solid rgba(240, 240, 240, 0.7);
    -webkit-box-shadow: 1px 2px 15px rgba(240, 240, 240, 0.7) inset, -2px -3px 15px rgba(240, 240, 240, 0.7) inset, 1px 2px 15px rgba(225, 225, 225, 0.7), -2px -3px 15px rgba(225, 225, 225, 0.7), 6px 5px 7px rgba(205, 205, 205, 0.7);
            box-shadow: 1px 2px 15px rgba(240, 240, 240, 0.7) inset, -2px -3px 15px rgba(240, 240, 240, 0.7) inset, 1px 2px 15px rgba(225, 225, 225, 0.7), -2px -3px 15px rgba(225, 225, 225, 0.7), 6px 5px 7px rgba(205, 205, 205, 0.7);
  }
  
  .channel-height {
    height: 65vh;
  }
  
  
  div.message-button,
  button.message-button {
    height: 4.5rem;
    padding: 0 0 0.5em 0;
  }
  
  .channel {
	  flex-grow: 1;
	  top: 0;
	  bottom: 11rem;
    overflow-y: scroll;
  }
  
  .unable-resize {
    resize: none;
  }
  
  .whitespace-newline {
    white-space: pre-line;
  }
  
  #nameInput + label,
  #textInput + label {
    -webkit-transition: opacity 0.5 ease-in-out, -webkit-transform 0.5s ease-in-out;
    transition: opacity 0.5 ease-in-out, -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, opacity 0.5 ease-in-out;
    transition: transform 0.5s ease-in-out, opacity 0.5 ease-in-out, -webkit-transform 0.5s ease-in-out;
    opacity: 1;
    overflow: hidden;
  }
  
  #nameInput:not(:placeholder-shown) + label,
  #nameInput:focus + label,
  #nameInput:active + label {
    -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(8.5%);
            transform: scale(0.85) translateY(-0.5rem) translateX(8.5%);
  }
  
  #textInput:focus + label,
  #textInput:active + label {
    -webkit-transform: scale(0.8) translateY(-1.5rem) translateX(0.5rem);
            transform: scale(0.8) translateY(-1.5rem) translateX(0.5rem);
    opacity: 0;
  }
  
  .text-break {
    white-space: normal;
  }
  
  .fs-7 {
    font-size: 0.8em;
  }
  
  .error-field {
    min-height: 1.5em;
    color: #3fc0b8;
  }
  
  .span-error {
    color: #969696;
  }

  
  @media (min-width: 576px) {
    div.message-button,
    button.message-button {
      padding: 0 0.5em 0 0;
    }
	.channel{
		bottom: 7rem;
	}
  }
  